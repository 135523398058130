<template>
  <div class="activityCenterBox">
    <div class="bgc" :class="[bgColor ? 'bgColor' : '']">
      <div class="topTitle">{{ $t("activityCenter.default[0]") }}</div>
    </div>
    <div class="content" ref="content">
      <div class="activityCenter_Logo">
        <img :src="`/static/images/activityCenterTitle_${Language}.png`" />
      </div>
      <div class="content_title_bg">
        <div
          :class="[
            'content_title',
            Language == 'cn' ? '' : 'content_title_falseCn',
          ]"
          v-html="$t('activityCenter.default[1]')"
        ></div>
      </div>
      <div class="content_title2 box">
        <img
          class="left"
          src="../../../public/static/images/activityCenter_swiper_item_left.png"
        />
        <van-swipe :show-indicators="false" vertical :autoplay="2000">
          <van-swipe-item v-for="(item, index) in swiperList" :key="index">
            <!-- 用户XXXX成功领取邀请好友彩金 -->
            <div
              class="van-swipe-item_content font"
              v-html="
                $t('activityCenter.default[2]', {
                  username: item.username,
                  money: item.money,
                  currency_name: currency_name,
                })
              "
            ></div>
          </van-swipe-item>
        </van-swipe>
        <img
          class="right"
          src="../../../public/static/images/activityCenter_swiper_item_right.png"
        />
      </div>
      <div class="content_operation">
        <!-- 活动福利 -->
        <div
          class="content_operation_title_box"
          :class="{ content_title_CN: isCN }"
        >
          <div
            class="content_operation_title"
            v-html="$t('activityCenter.default[3]')"
          ></div>
          <img
            class="content_operation_title_left"
            src="../../../public/static/images/activityCenter_title_left.png"
          />
          <img
            class="content_operation_title_right"
            src="../../../public/static/images/activityCenter_title_right.png"
          />
        </div>
        <div v-for="(item, index) in activityList" :key="item">
          <!-- 新手任务及其第一个的查看详情 -->
          <div class="operation_item operation_item_top" v-if="index % 2 == 0">
            <div class="operation_item_title">
              <img
                v-show="item.id == 44"
                :src="`static/images/activityCenter_item_Huo_${Language}.png`"
              />
              <img
                v-show="item.id == 46"
                :src="`static/images/activityCenter_item_Yao_${Language}.png`"
              />
              <img
                v-show="item.id == 47"
                :src="`static/images/activityCenter_item_Shou_${Language}.png`"
              />
              <img
                v-show="item.id == 48"
                :src="`static/images/activityCenter_item_Xin_${Language}.png`"
              />
              <img
                v-show="item.id == 49"
                :src="`static/images/activityCenter_item_Xia_${Language}.png`"
              />
            </div>
            <div class="operation_item_content operation_item_content_left">
              <p
                class="operation_item_content_title one"
                v-show="item.id == 44"
                v-html="$t('activityCenter.itemContent[0]')"
              ></p>
              <p
                class="operation_item_content_title one"
                v-show="item.id == 46"
                v-html="$t('activityCenter.itemContent[1]')"
              ></p>
              <p
                class="operation_item_content_title one"
                v-show="item.id == 47"
                v-html="$t('activityCenter.itemContent[2]')"
              ></p>
              <p
                class="operation_item_content_title one"
                v-show="item.id == 48"
                v-html="$t('activityCenter.itemContent[3]')"
              ></p>
              <p
                class="operation_item_content_title one"
                v-show="item.id == 49"
                v-html="$t('activityCenter.itemContent[3]')"
              ></p>
              <!-- <router-link :to="urlList[index]+'/'+item.id"> -->
              <!-- 右上按钮 -->
              <div
                class="operation_item_content_details"
                @click="toActivity(item.id)"
              >
                {{ $t("activityCenter.default[4]") }}
              </div>
              <!-- </router-link> -->
            </div>
          </div>
          <!-- 邀请好友和第二个任务详情 -->
          <div class="operation_item" v-else>
            <div class="operation_item_content operation_item_content_bg">
              <div class="operation_item_content_title2 two">
                <p
                  class="operation_item_content_title one"
                  v-show="item.id == 44"
                  v-html="$t('activityCenter.itemContent[0]')"
                ></p>
                <p
                  class="operation_item_content_title one"
                  v-show="item.id == 46"
                  v-html="$t('activityCenter.itemContent[1]')"
                ></p>
                <p
                  class="operation_item_content_title one"
                  v-show="item.id == 47"
                  v-html="$t('activityCenter.itemContent[2]')"
                ></p>
                <p
                  class="operation_item_content_title one"
                  v-show="item.id == 48"
                  v-html="$t('activityCenter.itemContent[3]')"
                ></p>
                <p
                  class="operation_item_content_title one"
                  v-show="item.id == 49"
                  v-html="$t('activityCenter.itemContent[3]')"
                ></p>
              </div>
              <!-- 邀请活动需要两个参数 -->
              <!-- <router-link :to="urlList[index]+`/${index==1?item.id+'/'+activityList[0].id:''}`"> -->
              <div
                class="operation_item_content_details2"
                @click="toActivity(item.id)"
              >
                {{ $t("activityCenter.default[4]") }}
              </div>
              <!-- </router-link> -->
            </div>
            <div class="operation_item_title">
              <img
                v-show="item.id == 44"
                :src="`static/images/activityCenter_item_Huo_${Language}.png`"
              />
              <img
                v-show="item.id == 46"
                :src="`static/images/activityCenter_item_Yao_${Language}.png`"
              />
              <img
                v-show="item.id == 47"
                :src="`static/images/activityCenter_item_Shou_${Language}.png`"
              />
              <img
                v-show="item.id == 48"
                :src="`static/images/activityCenter_item_Xin_${Language}.png`"
              />
              <img
                v-show="item.id == 49"
                :src="`static/images/activityCenter_item_Xia_${Language}.png`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: "activityCenter",
  components: {},
  data() {
    return {
      isCN: localStorage.getItem("Language") == "cn",
      bgColor: false,
      swiperList: [], //轮播list
      activityList: [], //活动list
      urlList: [
        "/user/signInToLavishGifts",
        "/user/invite",
        "/user/theFirsetToShare",
        "/user/newpeople",
      ],
      Language: localStorage["Language"] ? localStorage["Language"] : "vn", //当前语言
      currency_name: JSON.parse(localStorage.getItem("InitData")).setting
        .currency_name, //货币符号
    };
  },
  computed: {},
  created() {
    //标题
    this.$parent.navBarTitle = "";
    this.swiperList = this.ActivityCenterList
      ? this.ActivityCenterList.rolling
      : []; //轮播list
    this.activityList = this.ActivityCenterList
      ? this.ActivityCenterList.items.data
      : []; //活动list
    // this.GetWithdrawType()
    // this.isSubmit = true;
    // this.isSubmit = false;
    this.getActivityGetList();
  },
  mounted() {
    this.pageScroll();
    // console.log(this.activityList)
  },
  activated() {},
  destroyed() {
    // 在组件生命周期结束的时候要销毁监听事件，否则其他页面也会使用这个监听事件
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    //活动中心
    getActivityGetList() {
      if (!localStorage["Token"]) {
        this.$router.push("/login");
        return;
      }
      this.$Model.ActivityGetList((data) => {
        this.swiperList = data.data.rolling;
        this.activityList = data.data.items.data;
      });
    },
    pageScroll() {
      // 监听滚动事件，用handleScroll这个方法进行相应的处理
      window.addEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      let top = this.$refs.content.getBoundingClientRect().top;
      // 监听到页面滚动后的处理事件
      if (top > -32) {
        this.bgColor = false;
      } else {
        this.bgColor = true;
      }
    },
    toActivity(id) {
      switch (id) {
        case 44:
          this.$router.push({ path: "/user/signInToLavishGifts/" + id });
          break;
        case 46:
          this.$router.push({
            path: "/user/invite/" + id + "/" + this.activityList[0].id,
          });
          break;
        case 47:
          this.$router.push({ path: "/user/theFirsetToShare/" + id });
          break;
        case 48:
          this.$router.push({ path: "/user/newpeople", query: { id: id } });
          break;
        case 49:
          this.$router.push({
            path: "/user/InviteSubordinates",
            query: { id: id },
          });
      }
    },
  },
};
</script>

<style scoped>
/* .van-nav-bar--fixed,.Site .van-nav-bar{
	background-color: #0f25fe;
} */
.activityCenterBox {
  overflow: auto;
  height: 100vh;
  background-color: #fff;
}

.activityCenterBox .bgc {
  width: 100%;
  background-color: transparent;
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}

.bgColor {
  background-color: #4e51bf !important;
}

.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.height {
  height: 46px;
}

.activityCenterBox .content {
  background: url("../../../public/static/images/activityCenter_Bg.jpg")
    no-repeat;
  background-size: 100% 100%;
  padding: 20px 31px 250px;
  overflow: hidden;
}

.activityCenter_Logo {
  margin-top: 35px;
}

.activityCenter_Logo img {
  width: 118%;
  max-width: 500px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.content_title_bg {
  background: linear-gradient(0deg, #fd4136, #fcd071);
  padding: 4px;
  border-radius: 2rem;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: -20px;
}

.activityCenterBox .content .content_title {
  min-width: 100%;
  max-width: 600px;
  background: linear-gradient(41deg, #4e51bf, #d519bc);
  padding: 12px 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  font-size: 18px;
  /*  */
  font-weight: bold;
  color: #fff;
  text-align: center;
  /* color: #FE889D; */
}
.activityCenterBox .content .content_title_falseCn {
  font-weight: 500;
  font-size: 14px;
  padding: 5px 5px;
}
.content_title_CN {
  letter-spacing: 0.2rem;
}

.activityCenterBox .content .content_title span {
  padding-left: 18px;
}

.activityCenterBox .content .content_title2 {
  min-width: 100%;
  max-width: 600px;
  padding: 7px 0 20px;
  text-align: center;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activityCenterBox .content .content_title2 img {
  height: 22px;
}

.activityCenterBox .content .content_title2 img.right {
  height: 30px;
}

.activityCenterBox >>> .van-swipe {
  height: 1rem;
  padding: 0 0px 0 10px;
}

.activityCenterBox >>> .van-swipe-item {
  width: 100%;
  height: 1rem;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.van-swipe-item_content {
  overflow: hidden;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  color: #fff;
  font-size: 12px;
}

.activityCenterBox .content_operation {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 45px 25px 30px;
}

.content_operation .content_operation_title_box {
  display: flex;
  justify-content: center;
  background: linear-gradient(0deg, #f19a73, #fcd271);
  max-width: 350px;
  width: 59%;
  min-width: 186px;
  border-radius: 6px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  height: 42px;
  font-size: 24px;

  font-weight: bold;
  color: #ff395b;
  padding: 10px 0 9px 0;
  align-items: center;
}

.content_operation .content_operation_title_box .content_operation_title_left {
  padding-bottom: 5px;
  width: 19px;
  position: absolute;
  left: -20px;
  top: -9px;
}

.content_operation .content_operation_title_box div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  text-align: center;
  display: flex;
  line-height: 1.1;
}

.content_operation .content_operation_title_box .content_operation_title_right {
  width: 11px;
  height: auto;
  padding-bottom: 2px;
  position: absolute;
  right: -12px;
  top: 0px;
}

.activityCenterBox .content_operation .operation_item {
  display: flex;
  justify-content: space-between;
  padding: 0px 0;
  margin-bottom: 7px;
}

/* .content_operation .operation_item_top {
		margin-top: 45px !important;
	} */

.activityCenterBox .content_operation .operation_item_title {
  font-size: 15px;

  font-weight: bold;
  color: #1b1b1b;
  flex-shrink: 0;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  position: relative;
  width: 40%;
}

.activityCenterBox .content_operation .operation_item_title img {
  max-width: 130px;
  width: 111%;
}

.activityCenterBox .content_operation .operation_item_title div {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;

  font-weight: normal;
  font-style: italic;
  color: #ffb7f4;
  text-shadow: 0px 7px 2px rgba(0, 0, 0, 0.34);
  -webkit-text-stroke: 0.4px #daae85;
  padding: 0 10px;
  font-size: 20px;
}

.activityCenterBox .content_operation .operation_item_content {
  padding: 10px 0 5px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  background: url(../../../public/static/images/activityCenter_swiper_item_right.png)
    no-repeat;
  background-size: 33%;
  background-position: right top;
}

.activityCenterBox .content_operation .operation_item_content_left {
  align-items: flex-start;
}

.activityCenterBox .content_operation .operation_item_content_bg {
  background-position: left top;
}

.content_operation .operation_item_content_title {
  font-size: 16px;
  color: #000;

  margin-bottom: 5px;
  position: relative;
  top: 6px;
  left: 6px;
  /* width: 50%; */
  min-width: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #fe889d;
  text-align: end;
  transform: scale(0.8);
}
.operation_item_content_title {
  font-size: 16px;
}
.content_operation .operation_item_content_title2 {
  color: #000;

  margin-bottom: 5px;
  position: relative;
  top: 6px;
  left: -15px;
  /* width: 50%; */
  min-width: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #fe889d;
  text-align: end;
}
.operation_item_content_title2 >>> .operation_item_content_title > p {
  text-align: end;
}

.content_operation .operation_item_content a {
  margin: 7px auto;
  width: 85%;
  max-width: 130px;
}

.content_operation .operation_item_content_details {
  position: relative;
  top: 0px;
  left: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fd4133;
  padding: 4px 0px;
  max-width: 300px;
  min-width: 130px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 20px;
  background: linear-gradient(91deg, #fde6a8 0%, #ff93a4 100%);
}

.content_operation .operation_item_content_details2 {
  position: relative;
  left: -10px;
  font-weight: bold;
  font-size: 16px;
  color: #fd4133;
  padding: 4px 0px;
  max-width: 300px;
  min-width: 130px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 20px;
  background: linear-gradient(91deg, #fde6a8 0%, #ff93a4 100%);
}

.activityCenterBox .more {
  width: 100%;
  background: linear-gradient(-90deg, #ffe9ce 0%, #b3b5fc 99%);
  border-radius: 10px;
  height: 90px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
}

.activityCenterBox .more .more_title {
  font-size: 18px;
  color: #4e51bf;
  font-weight: bold;

  margin-bottom: 10px;
}

.activityCenterBox .more .more_content {
  font-size: 15px;
  color: #000;

}

.activityCenterBox .the .operation_item_title .cn_font {
  font-size: 17px;
}

.one {
  text-align: left !important;
}

.two {
  text-align: right !important;
  width: 100% !important;
  padding-top: 10px;
}

.three {
  text-align: left !important;
  width: 100% !important;
}

.four {
  text-align: right !important;
  width: 100% !important;
  padding-top: 20px;
}

.content_operation .content_operation_title_box div[data-v-c44aee44] {
  text-align: center;
}

.coinimg {
  margin-top: -10px;
  height: 10px;
}

.coinimg img:nth-of-type(1) {
  width: 20px;
  position: absolute;
  top: -30px;
  left: 40px;
}

.coinimg img:nth-of-type(2) {
  width: 12px;
  position: absolute;
  top: -20px;
  left: 262px;
}

.font {
  font-size: 12px;
  transform: scale(0.9);
  margin-left: -15px;
}

.font span {
  font-size: 20px !important;
}

.box {
  width: 100%;
  position: relative;
}

/* 英文版样式单独样式 */

.en_bg {
  background: linear-gradient(0deg, #fd4136, #fcd071);
  padding: 4px;
  border-radius: 2rem;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: -20px;
  width: 100%;
  height: 48px;
}

.en_title {
  min-width: 100%;
  max-width: 600px;
  background: linear-gradient(41deg, #4e51bf, #d519bc);
  padding: 12px 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  font-size: 16px;
  letter-spacing: 1px;
  /*  */
  font-weight: bold;
  color: #fff;
  height: 40px;
  line-height: 15px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* color: #FE889D; */
}

.activityCenterBox .the .operation_item_title .en_font {
  font-size: 14.5px;
}
</style>
