import { render, staticRenderFns } from "./activityCenter.vue?vue&type=template&id=1acdd781&scoped=true"
import script from "./activityCenter.vue?vue&type=script&lang=js"
export * from "./activityCenter.vue?vue&type=script&lang=js"
import style0 from "./activityCenter.vue?vue&type=style&index=0&id=1acdd781&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1acdd781",
  null
  
)

export default component.exports